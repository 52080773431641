import { ChatContainer, Conversation, ConversationHeader, ConversationList, MainContainer } from "@chatscope/chat-ui-kit-react";
import { WAConversationList } from "../whatsapp/list";
import {Avatar} from "@chatscope/chat-ui-kit-react";
import { useEffect, useState } from "react";
import { sendServerRequest } from "../../server/request";
import { URLS } from "../../env/urls";
import { openGlobalSnack } from "../utils/snack";
import { MessagesContainer } from "./chat";
import { FgtsMessages } from "../whatsapp/types";


export interface ChatInfoSearch{
    rootNumber:string,
    clientNumber:string
}
export function WASearch({chats}:{chats:Array<ChatInfoSearch>}){
    const [selectedChat,setSelectedChat]=useState<undefined|ChatInfoSearch>();
    const [loading,setLoading]=useState(false);
    const [itemsHeight,setItemsHeight]=useState(window.innerHeight-200);
    const [messages,setMessages]=useState<Array<FgtsMessages>>([]);
    useEffect(()=>{
        if(!selectedChat)
             return;
        if(loading)
            return;
        setLoading(true);
    },[selectedChat]);
    useEffect(()=>{
       if(loading){
          setLoading(false);
       }
    },[messages]);
    useEffect(()=>{
      if(!loading)
         return;
      if(!selectedChat)
        return;  
      sendServerRequest('GET',URLS.propostals.conversation(selectedChat.clientNumber,selectedChat.rootNumber)).then(e=>{
          const {messages}=e.data.data;
          setMessages(messages);
      }).catch(e=>{
          openGlobalSnack('Erro ao carregar conversas, atualize a pagina.','error');
          setMessages([]);
          setLoading(false);
      });
    },[loading]);

    return (
      <div style={{display:'flex',flexDirection:'row',padding:'20px',alignContent:'center',justifyContent:'center'}}>
          
          <TalkBase itemsHeight={itemsHeight} onClick={(e)=>setSelectedChat(e)} loading={loading} chats={chats}/>
          <div style={{minWidth:"600px",height: `${itemsHeight}px`}}>
          {!selectedChat||loading?<div  style={{height: `${itemsHeight}px`}} className="empty_chat_conteiner"></div>:
           <MessagesContainer messages={messages} height={itemsHeight} header={selectedChat}/>
          }
          </div>
      </div>
   )
}
function TalkBase({chats,loading,onClick,selectedChat,itemsHeight}:{itemsHeight:number,selectedChat?:ChatInfoSearch,onClick:(e:ChatInfoSearch)=>void,chats:Array<ChatInfoSearch>,loading?:boolean}){
   

   return (
    <div>
        
        <ConversationList
           style={{
            height: `${itemsHeight}px`,
            width:false?'100%':'300px',
            margin:'auto'
          }}
          loading={loading}
        >
           {chats.length>0?
               chats.map((e)=>{
                  return <Conversation
                  info={`Bot: ${e.rootNumber}`}
                  name={e.clientNumber}
                  
                  onClick={()=>onClick(e)}
                  active={selectedChat?.rootNumber==e.rootNumber&&selectedChat?.clientNumber==e.clientNumber}
                  
                  >
                   <Avatar
                     name={`${e.clientNumber}`}
                     src={`${process.env.PUBLIC_URL}/img/cpf.svg`}
                   />
                  </Conversation>
               })
           :<p>Sem Conversas</p>}   
        </ConversationList>
       
    </div>
   )
}