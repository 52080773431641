import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';

import LogoutIcon from '@mui/icons-material/Logout';
import { ROUTES } from '../../env/router';
import { Logout } from '../user/logout';
import { WAConteiner } from '../whatsapp/container';
import { Dashboard } from '../dashboard/dash';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import GradingIcon from '@mui/icons-material/Grading';
import { Propostals } from '../propostals/propostals';
import { CashBook } from '../cachbook/cachbook';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { ConversationSearch } from '../whatsapp/talk';
export const mainListItems = 
    [ 
      {
        item:(<ListItemButton>
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Caixa" />
        </ListItemButton>),
        content:<CashBook/>,
        url:`${ROUTES.control}/caixa`,
      },
      {item:(<ListItemButton>
      <ListItemIcon>
        <QueryStatsIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>),
    url:`${ROUTES.control}/dashboard`,
    content:<Dashboard/>
    },
      {item:(<ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Conversas" />
    </ListItemButton>),
    url:`${ROUTES.control}/conversas`,
    content:<WAConteiner/>
    },
    {item:(<ListItemButton>
      <ListItemIcon>
        <GradingIcon />
      </ListItemIcon>
      <ListItemText primary="Propostas" />
    </ListItemButton>),
    url:`${ROUTES.control}/propostas`,
    content:<Propostals/>
    },
    {item:(<ListItemButton>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Conversa Especifica" />
    </ListItemButton>),
    url:`${ROUTES.control}/conversa/especifica`,
    content:<ConversationSearch/>
    }
  ]



export const secondaryListItems = [

    {item:(<ListItemButton>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Deslogar" />
    </ListItemButton>),
    url:`${ROUTES.control}/sair`,
    content:<Logout/>
   }
]
;