import  "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import { CircularLoading } from "../utils/loading";
import { formatDateWithMin } from "../../utils/date";
import { ChatInfo, FgtsMessages } from "../whatsapp/types";
import { getCurrentStateAvatarSrc } from "../whatsapp/avatar";
import { useEffect, useRef, useState } from "react";
import {  Dialog, DialogContent, IconButton } from "@mui/material";
import ExpansiveButton from "../utils/viewer/expansivebutton";

import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

import VideoFileIcon from '@mui/icons-material/VideoFile';
import { ChatInfoSearch } from "./wacontainers";



export interface ChatMessageModel{
    message:string,
    direction:string,
    type:'text'|'video'|'audio'|'image'|'document',
    date:string,
    id:number,
    media_path?:string,
    mime_type?:string,

}


export function MessagesContainer({messages,header,height}:{height:number,messages:Array<FgtsMessages>,header:ChatInfoSearch}){


  return (
    <div style={{ position: "relative", height: `${height}px` }}>
  <MainContainer style={{width:'100%'}}>
    <ChatContainer>
    
    <ConversationHeader>
     
       <Avatar 
          name={header.clientNumber}
          src={`${process.env.PUBLIC_URL}/img/cpf.svg`}
       
       />
        <ConversationHeader.Content
            userName={header.clientNumber}
            info={`Bot: ${header.rootNumber}`}
        />
       <ConversationHeader.Actions>
            <ExpansiveButton items={[]}/>
       </ConversationHeader.Actions>
    </ConversationHeader>
      <MessageList>
           {messages.map(item=>{
               return (<MessageBody msg={item}/>)
           })}
      </MessageList>
      
    
    </ChatContainer>
  </MainContainer>
  
  </div>
   )
   
}




function MessageBody({msg}:{msg:FgtsMessages}){
      let item=undefined;
      const [openDialog,setOpenDialog]=useState(false);
      let dcontent=null;
      let hasDialog=false;
      const itemPath=getItemPath(msg.id,msg.media_id||msg.sender_media_link||'');
      switch(msg.type){
          case 'image':
              
              item=<Message.ImageContent src={itemPath} width={100} height={100}/>
              hasDialog=true;
              dcontent=<div style={{textAlign:'center'}}><img style={{margin:'auto'}} src={itemPath} width={'80%'}/></div>
              break;
          case 'audio':
             item=(<Message.CustomContent >
                  <audio style={{height:'30px',maxWidth:'350px'}} controls>
                     <source src={itemPath} type='audio/ogg'/>
                  </audio>
                  </Message.CustomContent> )   
             break;
          case 'document':
              item=(<Message.CustomContent >
                <IconButton onClick={()=>window.open(itemPath)}><AssignmentReturnedIcon/></IconButton>
                </Message.CustomContent>)
              break;
          case 'video':
            
            item=(<Message.CustomContent >
              <IconButton><VideoFileIcon/></IconButton>
              </Message.CustomContent>)
             hasDialog=true;
             dcontent=<div style={{textAlign:'center'}}><video controls style={{margin:'auto'}} src={itemPath} width={'80%'}/></div>
              break;    
          default:
            item=<Message.TextContent text={msg.text}/> 
            break;
      }
      return (
        <>
        <Message
           onClick={()=>{
              if(hasDialog)
                setOpenDialog(true);
            }}
            style={{cursor:`${hasDialog?'pointer':null}`}}
           //@ts-ignore
           model={{
             //@ts-ignore
             direction:msg.sender===msg.root_sender_number?'outgoing':'incoming',
           }}
        >
         {item}
         
         <Message.Footer>{`${formatDateWithMin(msg.createdAt)} ${msg.meta_message_id}`}</Message.Footer>
       </Message>  
       {hasDialog?<Dialog 
           open={openDialog}
           onClose={()=>setOpenDialog(false)}
        >
           <DialogContent>
                {dcontent}
           </DialogContent>
        </Dialog>:null}     
        </>)
      function getItemPath(id:number,path:string){
          if(path.indexOf('https://')==0)
             return path;
          return `/server/media/file/${id}/${path}`  
      }  
     
}