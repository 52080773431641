export const URLS={
    user:{
        auth:{
            login:'/server/userauth/login',
            logout:'/server/userauth/logout'
        },
        info:'/server/user/info',        
    },
    cachBook:{
        finalCash:'/server/cashbook/finalCash',
        transitions:'/server/cashbook/book',

    },
    ws:{
        getToken:'/server/wsauth/gettoken',
        chats:'/server/ws/chat'
    },
    chatsInfo:{
        number:'/server/chatsinfo/numbers',
        v8:'/server/chatsinfo/v8logins'
    },
    fgtsChat:{
        updateState:'/server/support/updateFgtsChatState',
    },
    wa:{
        sendText:'/server/wa/sendText',
    },
    propostals:{
       getInfo:'/server/propostals/propostalInfo',
       get:'/server/propostals/propostals',
       search:(type:string,sr:string)=>`/server/propostals/propostals/search/${type}/${sr}`,
       conversation:(clientPhone:string,rootPhone:string)=>`/server/propostals/propostals/conversation/${clientPhone}/${rootPhone}`,
       getComplete:'/server/propostals/propostalsComplete',
       setSupport:'/server/propostals/porpostalSupport'
    },
    solve:{
        uploadDocs:'/server/solve/uploadDocs',
        hasDoc:'/server/solve/hasDoc',
        hasPendency:'/server/solve/haspendency',
        resolve:'/server/solve/solvependency'
    }
}