import { useState,useEffect } from "react";
import { userGetInfo } from "../../user/info";
import { openGlobalSnack } from "../utils/snack";
import { sendServerRequest } from "../../server/request";
import { URLS } from "../../env/urls";
import { CircularLoading } from "../utils/loading";
import { SelectFromItems } from "../utils/forms/select";
import { AppBar, Button, Card, CardActionArea, CardActions, CardContent, Dialog, DialogActions, DialogContent, Paper, TextField, Toolbar } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { formatDateWithMin } from "../../utils/date";
import { FgtsClient } from "../../types/clients";
import { ChatInfo, FgtsChat, FgtsPropostal } from "./types";
import { formatLocalCurrency } from "../../utils/currency";
import { getTableNameByID } from "../dashboard/types";
import { PropostalStatus, propostalStatusPTBR } from "../types/propostals";
import { ChatInfoSearch, WASearch } from "../statischat/wacontainers";

export function ConversationSearch(){
     const [canSeeConversation,setCanSeeConversation]=useState<null|boolean>(null);
     const [searchType,setSerchType]=useState<string>('CPF');
     const [searchValue,setSearchValue]=useState<string>('');
     const [loading,setLoading]=useState(false);
     const [data,setData]=useState<any>(null);
     useEffect(()=>{
        userGetInfo().then(e=>{
               if(!e){
                openGlobalSnack('Atualize a página','error');
                return;
               }
               setCanSeeConversation(e.permission.can_see_conversation);
        }).catch(e=>{
             openGlobalSnack('Atualize a página','error');
        })
        return ()=>{}
     },[]);
     useEffect(()=>{
        if(!loading)
             return;
        let usType=searchType=='Número'?'number':searchType;
        sendServerRequest("GET",URLS.propostals.search(usType.toLowerCase(),searchValue)).then(e=>{
            const {data}=e.data;
            console.log(data);
            setData(data);
            setLoading(false);
        }).catch(e=>{
             openGlobalSnack("Erro ao carregar, atualize a página.",'error');
             setLoading(false);
        })    
     },[loading])

    
     
     return (
         <>
            {!canSeeConversation?
              <div>
                {typeof(canSeeConversation)==='boolean'?<p>Acesso Negado.</p>:<CircularLoading/>}
              </div>
            :<div>
            
               <>
               <div style={{margin:'auto',marginTop:'25px',width:'90%',maxWidth:'350px'}}>
               <SelectFromItems items={['CPF','Número']} defaultValue="CPF" label={'Pesquisar por:'} onChangeItem={(i)=>{setSerchType(i)}}/>
                <TextField onChange={(e)=>{setSearchValue(e.target.value)}} label="Pesquisa" style={{marginTop:'10px',marginBottom:'8px'}} fullWidth/>
                <LoadingButton onClick={()=>setLoading(true)} loading={loading} disabled={!searchValue} variant="contained" fullWidth>Procurar</LoadingButton>
               </div>
               <div style={{marginBottom:'25px'}}></div>
                
               </>
               <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-around',alignItems:'center'}}>
                    {data?data.map((e:any)=>{
                        return (<ClientCard chats={e.chats} client={e.client} propostals={e.propostals}/>)
                    }):null}
               </div>
         </div>}
         </>
         
     )
}

function ClientCard({client,propostals,chats}:{chats:Array<FgtsChat>,client:FgtsClient,propostals:Array<FgtsPropostal>}){
    const [open,setOpen]=useState(false);
    const [openConversation,setOpenConversation]=useState(false);
    return (
        <>
        <Paper>
        <Card style={{maxWidth:'300px'}}>
           
            <CardContent>
                <p>{`Telefone: ${client.phone}`}</p>
                <p>{`Cpf: ${client.cpf||'Desconhecido.'}`}</p>
                <p>{`Nome: ${client.name||'Desconhecido.'}`}</p>
                <p>{`Nome da mãe: ${client.mother_name||'Desconhecido'}`}</p>
                <p>{`Data de nascimento: ${client.birth_date||'Desconhecido.'}`}</p>
                <p>{`Bloqueado: ${client.isBlocked?'SIM':'NÃO'}`}</p>
                <p>{`Criado: ${formatDateWithMin(client.createdAt)}`}</p>
                <p>{`Ultima consulta: ${formatDateWithMin(client.last_consult)}`}</p>
            </CardContent>
            <CardActions>
                <Button disabled={chats.length<1} onClick={()=>{setOpenConversation(true)}}>Ver Conversas</Button>
                <Button disabled={propostals.length<1} onClick={()=>setOpen(true)}>Ver Propostas</Button>
            </CardActions>
        </Card>
        </Paper>
        <PropostalDialog close={()=>{setOpen(false)}} open={open} propostals={propostals}/>
        <ConversationDialog client={client} chats={chats} open={openConversation} close={()=>{setOpenConversation(false)}}/>
        </>
    )
}
function ConversationDialog({client,chats,open,close}:{client:FgtsClient,chats:Array<FgtsChat>,open:boolean,close:()=>void}){
    const [clientChats,setClientChats]=useState<Array<ChatInfoSearch>>([]);
    useEffect(()=>{ 
       const numbers=[];
       for(let c of chats){
          numbers.push(c.sender_number);
       };
       const uniqueNumbers=Array.from(new Set(numbers));
       let chatsInfo:Array<ChatInfoSearch>=[];
       for(let num of uniqueNumbers){
           chatsInfo.push({
              clientNumber:client.phone,
              rootNumber:num,
           })
       }
       setClientChats(chatsInfo);
    },[chats]);
    return (
        <Dialog open={open} fullScreen>
            <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            
           
            <Button autoFocus variant="contained" color="warning"  onClick={close}>
              Fechar Coversas
            </Button>
          </Toolbar>
        </AppBar>
            <DialogContent>
                  <WASearch chats={clientChats}/>
            </DialogContent>
            
        </Dialog>
    )
}
function PropostalDialog({propostals,open,close}:{propostals:Array<FgtsPropostal>,open:boolean,close:()=>void}){
     return (<Dialog open={open}>
          <DialogContent>
                 <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-beetwem',alignItems:'center'}}>
                 {propostals.map((e)=>{
                    return (
                        
                             <Card style={{maxWidth:'500px',marginTop:'15px',marginLeft:'15px'}}>
                                <CardContent>
                                      <p>{`Id: ${e.propostal_id}`}</p>
                                      <p>{`Link: ${e.formalization_link}`}</p>
                                      <p>{`Valor: ${formatLocalCurrency(e.client_withdrawn_value)}`}</p>
                                      <p>{`Status: ${propostalStatusPTBR(e.status as PropostalStatus)}`}</p>
                                      <p>{`Tabela: ${getTableNameByID(e.table_fee_id)}`}</p>
                                      <p>{`Criado: ${formatDateWithMin(e.createdAt)}`}</p>
                                      <p>{`Bot: ${e.sender_number}`}</p>
                                      <p>{`Número Cliente ${e.from_phone}`}</p>
                                </CardContent>
                             </Card>
                        
                    )
                 })}
                 </div>
          </DialogContent>
          <DialogActions>
              <Button onClick={close} variant="contained">Sair</Button>
          </DialogActions>
     </Dialog>)
}
